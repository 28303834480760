<template>
  <button
    class="Button"
    :style="buttonStyles"
    @click="() => this.$emit('click')"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: String,
    filled: {
      type: Boolean,
      default: true,
    },
    textColor: {
      type: String,
      default: '#F4F4F4',
    },
    backgroundColor: {
      type: String,
      default: 'transparent',
    },
    borderColor: {
      type: String,
      default: '#F4F4F4',
    },
  },
  data() {
    return {
      buttonStyles: {
        backgroundColor: this.backgroundColor,
        borderColor: this.borderColor,
        color: this.textColor,
      },
    }
  },
}
</script>

<style lang="sass" scoped>
@import "src/styles/variables"
.Button
  background-color: transparent
  color: $bg-color-primary
  border: 1.5px solid $bg-color-primary
  border-radius: 8px
  height: 50px
  width: 100%
  font-weight: 600
  font-size: 14px
  line-height: 19px
</style>
