<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 11 20"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6259 2.9238C11.0172 2.5325 11.0163 1.8978 10.6239 1.5076L10.2625 1.14819C9.87148 0.7594 9.23958 0.76045 8.84989 1.15053L0.3714 9.6376C-0.0187872 10.0282 -0.0186266 10.6611 0.371758 11.0515L8.8579 19.5376C9.24843 19.9281 9.88159 19.9281 10.2721 19.5376L10.6279 19.1818C11.0184 18.7913 11.0184 18.1581 10.6279 17.7676L3.91212 11.0518C3.52159 10.6613 3.52159 10.0281 3.91212 9.63759L10.6259 2.9238Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconNavArrow',
  props: {
    width: {
      type: Number,
      default: 11,
    },
    height: {
      type: Number,
      default: 20,
    },
    color: String,
  },
}
</script>
