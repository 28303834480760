const samples = [
  {
    name: 'Sample #1',
    id: '0',
    roast: {
      score: 1,
      notes: '',
    },
    fragrance: {
      score: 8.0,
      magnitude: 3,
      notes: '',
    },
    aroma: {
      score: 8.0,
      magnitude: 3,
      notes: '',
    },
    flavor: {
      score: 8.0,
      magnitude: 3,
      notes: '',
    },
    acidity: {
      score: 8.0,
      magnitude: 3,
      notes: '',
    },
  },
  {
    name: 'Sample #2',
    id: '1',
    roast: {
      score: 2,
      notes: '',
    },
    fragrance: {
      score: 8.0,
      magnitude: 3,
      notes: '',
    },
    aroma: {
      score: 8.0,
      magnitude: 3,
      notes: '',
    },
    flavor: {
      score: 8.0,
      magnitude: 3,
      notes: '',
    },
    acidity: {
      score: 8.0,
      magnitude: 3,
      notes: '',
    },
  },
  {
    name: 'Sample #3',
    id: '2',
    roast: {
      score: 3,
      notes: '',
    },
    fragrance: {
      score: 8.0,
      magnitude: 3,
      notes: '',
    },
    aroma: {
      score: 8.0,
      magnitude: 3,
      notes: '',
    },
    flavor: {
      score: 8.0,
      magnitude: 3,
      notes: '',
    },
    acidity: {
      score: 8.0,
      magnitude: 3,
      notes: '',
    },
  },
  {
    name: 'Sample #4',
    id: '3',
    roast: {
      score: 4,
      notes: '',
    },
    fragrance: {
      score: 8.0,
      magnitude: 3,
      notes: '',
    },
    aroma: {
      score: 8.0,
      magnitude: 3,
      notes: '',
    },
    flavor: {
      score: 8.0,
      magnitude: 3,
      notes: '',
    },
    acidity: {
      score: 8.0,
      magnitude: 3,
      notes: '',
    },
  },
]
export default {
  isAuthenticated: false,
  samples,
}
