<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 15 16"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4293 2.48071C14.4683 2.44166 14.4683 2.37834 14.4293 2.33929L13.1607 1.07071C13.1217 1.03166 13.0583 1.03166 13.0193 1.07071L7.57071 6.51929C7.53166 6.55834 7.46834 6.55834 7.42929 6.51929L1.98071 1.07071C1.94166 1.03166 1.87834 1.03166 1.83929 1.07071L0.570711 2.33929C0.531658 2.37834 0.531658 2.44166 0.570711 2.48071L6.01929 7.92929C6.05834 7.96834 6.05834 8.03166 6.01929 8.07071L0.570711 13.5193C0.531658 13.5583 0.531658 13.6217 0.570711 13.6607L1.83929 14.9293C1.87834 14.9683 1.94166 14.9683 1.98071 14.9293L7.42929 9.48071C7.46834 9.44166 7.53166 9.44166 7.57071 9.48071L13.0193 14.9293C13.0583 14.9683 13.1217 14.9683 13.1607 14.9293L14.4293 13.6607C14.4683 13.6217 14.4683 13.5583 14.4293 13.5193L8.98071 8.07071C8.94166 8.03166 8.94166 7.96834 8.98071 7.92929L14.4293 2.48071Z"
      :stroke="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconClose',
  props: {
    width: {
      type: Number,
      default: 15,
    },
    height: {
      type: Number,
      default: 16,
    },
    color: String,
  },
}
</script>
