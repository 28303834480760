const SAMPLES_KEY = 'samples'
// const AUTH_KEY = 'isAuthenticated'

export default {
  updateSample({ state, commit }, updatedSample) {
    const updatedSampleId =
      typeof updatedSample === 'string' ? updatedSample : updatedSample.id
    state.samples = state.samples.map(sample =>
      sample.id === updatedSampleId ? updatedSample : sample
    )
    commit('UPDATE', { key: SAMPLES_KEY, value: state.samples })
  },
}
