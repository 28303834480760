<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 0C4.47998 0 0 4.48001 0 10C0 15.52 4.47998 20 10 20C15.52 20 20 15.52 20 10C20 4.48001 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconLock',
  props: {
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 20,
    },
    color: {
      type: String,
      default: '#272222',
    },
  },
}
</script>
