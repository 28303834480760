<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 15 11"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.6682 1.57202C14.6682 0.884521 14.045 0.322021 13.2833 0.322021H2.20396C1.44225 0.322021 0.819036 0.884521 0.819036 1.57202V9.07202C0.819036 9.75952 1.44225 10.322 2.20396 10.322H13.2833C14.045 10.322 14.6682 9.75952 14.6682 9.07202V1.57202ZM13.2833 1.57202L7.74364 4.69077L2.20396 1.57202H13.2833ZM7.74364 5.94702L2.20396 2.82202V9.07202H13.2833V2.82202L7.74364 5.94702Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconEmail',
  props: {
    width: {
      type: Number,
      default: 15,
    },
    height: {
      type: Number,
      default: 11,
    },
    color: String,
  },
}
</script>
