<template>
  <Card class="ScoreCard" :title="title">
    <template v-slot:indicator>
      <div class="ScoreCard--score">
        <div class="ScoreCard--score-ipart">{{ animatedScore }}.</div>
        <div class="ScoreCard--score-fpart">
          {{ animatedScoreFPart }}
        </div>
      </div>
    </template>
    <template v-slot:details>
      <div class="ScoreCard--magnitude-indicator">
        Intensity
        <div class="ScoreCard--magnitude-indicator-circles">
          <Circle
                  class="ScoreCard--magnitude-circle"
                  v-for="mag in magnitudeRange"
                  :key="mag"
                  color="#585858"
                  :style="`transition-delay: ${Math.abs(mag + transitionDelay)}00ms; opacity: ${mag <= sample[attribute].magnitude ? '1' : '.2'}`"
                  :width="`${7 + mag}px`" />
        </div>
      </div>
    </template>
    <template v-slot:content>
      <swiper
        class="ScoreCard--score-slider"
        slides-per-view="10"
        :centered-slides="true"
        :free-mode="true"
        :free-mode-sticky="true"
        :free-mode-momentum="false"
        @slideChange="onScoreChange"
        @swiper="onSwiper"
      >
        <Icon name="chevron" />
        <swiper-slide
          style=""
          class="ScoreCard--score-tick"
          v-for="(score, index) in scoreRange"
          :key="index"
        >
          <svg
            width="4"
            :height="computeTickHeight(index)"
            viewBox="0 0 4 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="2"
              y1="5"
              x2="2"
              y2="45"
              stroke="black"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
          <div class="ScoreCard--score-tick-label">
            {{ index % 4 === 0 ? score : '' }}
          </div>
        </swiper-slide>
      </swiper>
      <div class="ScoreCard--magnitude-selector">
        <div class="ScoreCard--magnitude-selector-circles">
          <Circle class="ScoreCard--magnitude-selector-circle"
                  v-for="mag in magnitudeRange"
                  :key="mag"
                  color="#585858"
                  :style="`opacity: ${mag === sample[attribute].magnitude ? '1' : '.2'}`"
                  :width="`${30 + (5 *  ((mag + 1) % 2))}px`"
                  @click="() => updateMagnitude(mag)"
          />
        </div>
        <div class="ScoreCard--magnitude-selector-labels">
          <div>Low</div>
          <div>High</div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from './Card'
import Circle from './Circle'
import Icon from './Icon'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { mapActions } from 'vuex'
import gsap from 'gsap/dist/gsap'

export default {
  name: 'ScoreCard',
  props: {
    sample: Object,
    score: Number,
    title: String,
    attribute: String,
  },
  components: {
    Card,
    Circle,
    Icon,
    Swiper,
    SwiperSlide,
  },
  computed: {
    animatedScore() {
      return Math.trunc(this.tweenedScore)
    },
    animatedScoreFPart() {
      return Math.abs((this.animatedScore - this.tweenedScore) * 100)
        .toFixed(0)
        .toString()
        .padEnd(2, '0')
    },
  },
  watch: {
    score(newValue) {
      gsap.to(this.$data, { duration: 0.5, tweenedScore: newValue })
    },
    /**
     * This monitors the sample's magnitude in order to apply the correct
     * transition delay based upon animation direction
     * @param newSample
     * @param oldSample
     */
    sample(newSample, oldSample) {
      const newMag = newSample[this.attribute].magnitude
      const oldMag = oldSample[this.attribute].magnitude
      if (newMag > oldMag) {
        // going right ----->
        this.transitionDelay = 0 - oldMag
      } else {
        // <----  going left
        this.transitionDelay = -4
      }
    },
  },
  data() {
    return {
      tweenedScore: Math.trunc(this.score),
      tweenedScoreFPart: Math.abs((Math.trunc(this.score) - this.score) * 100)
        .toFixed(0)
        .toString()
        .padEnd(2, '0'),
      scoreRange: Array(17)
        .fill(1)
        .map((el, i) => 6 + i * 0.25),
      magnitudeRange: [0, 1, 2, 3, 4],
      transitionDelay: 1
    }
  },
  methods: {
    ...mapActions(['updateSample']),
    computeTickHeight(index) {
      if (index % 4 === 0) {
        return 40
      } else if (index > 0 && index % 2 === 1) {
        return 10
      } else {
        return 20
      }
    },
    onScoreChange({ activeIndex }) {
      const updatedSample = {
        ...this.sample,
        [this.attribute]: {
          ...this.sample[this.attribute],
          score: this.scoreRange[activeIndex],
        },
      }
      this.updateSample(updatedSample)
    },
    updateMagnitude(magnitude) {
      const updatedSample = {
        ...this.sample,
        [this.attribute]: {
          ...this.sample[this.attribute],
          magnitude
        },
      }
      this.updateSample(updatedSample)
    },
    onSwiper(swiper) {
      swiper.slideTo(this.scoreRange.indexOf(this.score))
    },
  },
}
</script>

<style lang="sass" scoped>
.ScoreCard
  .ScoreCard--score
    align-items: baseline
    display: flex
    margin: auto
    .ScoreCard--score-ipart
      font-size: 22px
      line-height: 30px
    .ScoreCard--score-fpart
      font-size: 12px
      line-height: 16px
  .ScoreCard--score-tick
    align-self: center
    display: flex
    align-items: center
    justify-content: center
    flex-flow: column
    position: relative
  .ScoreCard--score-tick-label
    margin-top: 5px
    height: 20px
  .ScoreCard--score-slider
    align-items: center
    display: flex
    flex-flow: column-reverse
    &:after
      background: linear-gradient(90deg, rgba(255,255,255,1) 5%, rgba(255,255,255,0) 25%, rgba(255,255,255,0) 75%, rgba(255,255,255,1) 95%)
      content: ' '
      position: absolute
      width: 100%
      left: 0
      height: 100%
      top: 0
      z-index: 1
      pointer-events: none
  .ScoreCard--magnitude-indicator
    display: flex
    .ScoreCard--magnitude-indicator-circles
      margin-left: 6px
      .ScoreCard--magnitude-circle
        transition: opacity .3s
        &:not(:last-child)
          margin-right: 2px
  .ScoreCard--magnitude-selector
    display: flex
    flex-flow: column
    padding: 15px 25px
    .ScoreCard--magnitude-selector-circles
      align-items: center
      display: flex
      justify-content: space-between
      .ScoreCard--magnitude-selector-circle
        transition: opacity .3s
    .ScoreCard--magnitude-selector-labels
      display: flex
      justify-content: space-between
      margin-top: 10px

</style>
