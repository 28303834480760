<template>
  <div class="Cupping" ref="page">
    <div class="Cupping--navbar">
      <Icon name="nav-arrow" />
      <Icon name="menu" />
    </div>
    <swiper
      style="width: 100%;"
      :slides-per-view="3"
      :centered-slides="true"
      :free-mode="true"
      @swiper="onTitleSwiper"
      @touchEnd="onTitleTouchEnd"
    >
      <swiper-slide
        v-for="(sample, index) in samples"
        :key="sample.id"
        @click="onTitleClick(sample, index)"
        >{{ sample.name }}</swiper-slide
      >
      <div class="Cupping&#45;&#45;tab-titles-dot">●</div>
    </swiper>
    <swiper
      class="Cupping--tabs"
      style="width: 100%; height: 100%;"
      @swiper="onTabSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        class="Cupping--tab"
        v-for="sample in samples"
        :key="sample.id"
      >
        <RoastCard :sample="sample" />
        <ScoreCard
          :sample="sample"
          :score="sample.fragrance.score"
          attribute="fragrance"
          title="Fragrance"
        />
        <ScoreCard
                :sample="sample"
                :score="sample.aroma.score"
                attribute="aroma"
                title="Aroma"
        />
        <ScoreCard
                :sample="sample"
                :score="sample.acidity.score"
                attribute="acidity"
                title="Acidity"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import RoastCard from '../components/RoastCard'
import ScoreCard from '../components/ScoreCard'
import Icon from '../components/Icon'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { mapGetters } from 'vuex'
import 'swiper/swiper.scss'

import Roast from '../models/Roast'

export default {
  name: 'Cupping',
  components: {
    RoastCard,
    ScoreCard,
    Icon,
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapGetters(['samples']),
  },
  data() {
    return {
      selectedSample: 0,
      selectedSampleIndex: 0,
      scrollTimer: null,
      titleSwiper: null,
      tabSwiper: null,
      Roast,
    }
  },
  methods: {
    onTitleSwiper(swiper) {
      this.titleSwiper = swiper
    },
    onTabSwiper(swiper) {
      this.tabSwiper = swiper
    },
    /**
     * Returns the title back to the currently selected sample after user
     * stops touching the title slider
     * @param
     */
    onTitleTouchEnd(swiper) {
      if (this.scrollTimer !== null) {
        clearTimeout(this.scrollTimer)
      }
      this.scrollTimer = setTimeout(() => {
        swiper.slideToLoop(
          this.tabSwiper.activeIndex,
          100
        )
      }, 580)
    },
    onTitleClick(sample, index) {
      this.selectedSample = sample
      this.syncSlides(index)
    },
    onSlideChange({ activeIndex }) {
      this.selectedSample = this.samples[activeIndex]
      this.syncSlides(activeIndex)
    },
    /**
     * Syncs up both the titles and the tab view slides
     * @param index - the index of the currently active slide
     */
    syncSlides(index) {
      this.titleSwiper.slideTo(index)
      this.tabSwiper.slideTo(index)
      this.selectedSampleIndex = index
    }
  },
}
</script>

<style lang="sass" scoped>
.Cupping
  align-items: center
  box-sizing: border-box
  flex-flow: column
  height: 100%
  overflow: hidden
  .Cupping--navbar
    align-items: center
    box-sizing: border-box
    display: flex
    justify-content: space-between
    margin-bottom: 35px
    padding: 30px
    width: 100%
  .Cupping--tab-titles
    display: flex
    overflow-x: scroll
    width: 100%
    .Cupping--tab-title
      display: flex
      flex-flow: column
      margin-right: 24px
      min-width: fit-content
      padding: 5px
      transition: all .3s
      &:after
        opacity: 0
        content: '●'
        transition: opacity 100ms
      &[selected="true"]
        font-weight: 500
        &:after
          opacity: 1
  .Cupping--tab-titles-dot
    font-size: 10px
  .Cupping--tabs
    display: flex
    height: 100%
    overflow-y: scroll
    .Cupping--tab
      box-sizing: border-box
      height: 100%
      min-width: 100%
      padding: 30px
      position: relative
      scroll-snap-align: start
      text-align: center
      &> *:not(:last-child)
        margin-bottom: 20px
</style>
