export default class Roast {
  constructor(data) {
    this.score = data.score ?? Roast.LIGHT
  }

  toLabel() {
    return {
      [Roast.LIGHT]: 'light',
      [Roast.CINNAMON]: 'cinnamon',
      [Roast.STANDARD]: 'standard',
      [Roast.MEDIUM]: 'medium',
      [Roast.DARK]: 'dark',
    }[this.score]
  }
  toColor() {
    return Roast.toColor(this.score)
  }
  static toColor(roastScore) {
    switch (roastScore) {
      case Roast.LIGHT:
        return '#C6955B'
      case Roast.CINNAMON:
        return '#93642C'
      case Roast.STANDARD:
        return '#7C480A'
      case Roast.MEDIUM:
        return '#58350C'
      case Roast.DARK:
        return '#3B2000'
      default:
        return ''
    }
  }
  static LIGHT = 0
  static CINNAMON = 1
  static STANDARD = 2
  static MEDIUM = 3
  static DARK = 4
}
