<template>
  <div class="Scan">
    <div class="Scan--nav-back" @click="goBack">
      <Icon name="nav-arrow" />
    </div>
    <div class="Scan--header">
      <div class="Scan--header-title">
        Scan QR code
      </div>
      <div class="Scan--header-subtitle">
        Place QR Code inside the frame.
      </div>
    </div>
    <div class="Scan--scanner">
      <Icon
        class="Scan--scanner-icon"
        height="100%"
        width="100%"
        color="#272222"
        v-if="!scanning"
        name="scan"
      />
      <qrcode-stream v-if="scanning" @decode="onDecode" @init="onScannerInit">
        <Loader class="Scan--scanner-loader" v-if="scannerLoading" />
      </qrcode-stream>
      <!--      <div v-if="scanning && !scannerLoading">Looking for QR code...</div>-->
    </div>
    <div class="Scan--buttons">
      <Button
        :text="scanning ? 'Stop scanning' : 'Scan now'"
        background-color="#453E44"
        @click="scanning = !scanning"
      />
      <div class="Scan--buttons-divider">
        <hr />
        <span class="Scan--buttons-divider-text">or</span>
        <hr />
      </div>
      <Button text="Enter code" text-color="#453E44" border-color="#453E44" />
    </div>
  </div>
</template>

<script>
import Button from '../components/Button'
import Icon from '../components/Icon'
import Loader from '../components/Loader.vue'
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  name: 'Scan',
  components: {
    Button,
    Loader,
    Icon,
    QrcodeStream,
  },
  data() {
    return {
      scanning: false,
      scannerLoading: false,
    }
  },
  methods: {
    onDecode(data) {
      const [prefix, content] = data.split(':')
      if (prefix === 'code') {
        this.scanning = false
        console.log('valid')
      }
      console.log({ prefix, content, data })
    },
    async onScannerInit(promise) {
      this.scannerLoading = true
      try {
        await promise
      } catch (error) {
        console.error(error)
      } finally {
        this.scannerLoading = false
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
  },
}
</script>

<style lang="sass" scoped>
@import "src/styles/variables"

.Scan
  align-items: center
  box-sizing: border-box
  display: flex
  flex-flow: column
  height: 100%
  justify-content: space-between
  padding: 30px
  .Scan--nav-back
    position: absolute
    left: 30px
  .Scan--header
    color: $text-color-blackish
    margin-top: 50px
    width: 60vw
    .Scan--header-title
      font-weight: 500
      font-size: 26px
      line-height: 40px
      margin-bottom: 5px
    .Scan--header-subtitle
      font-size: 16px
  .Scan--scanner
    .Scan--scanner-icon
      opacity: .8
    .Scan--scanner-loader
      top: 40%
  .Scan--buttons
    width: 100%
    .Scan--buttons-divider
      color: $fg-color-primary
      display: flex
      margin: 24px 0
      .Scan--buttons-divider-text
        width: 90px
      hr
        border-radius: 7px
        border-style: solid
        width: 100%
</style>
<style lang="sass">
video
  border-radius: 5px
  box-shadow: 5px 6px 20px 4px rgb(0 0 0 / 0.25)
</style>
