<template>
  <svg
    :height="height"
    :width="width"
    :fill="color"
    style="enable-background: new 0 0 512 512"
    viewBox="0 0 13 11"
    x="0px"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <path
      d="M7.36107 9.54303C7.27266 9.69271 7.14674 9.81676 6.99575 9.90293C6.84477 9.9891 6.67392 10.0344 6.50007 10.0344C6.32623 10.0344 6.15538 9.9891 6.0044 9.90293C5.85341 9.81676 5.72749 9.69271 5.63907 9.54303L0.891074 1.50903C0.801266 1.35715 0.753241 1.18422 0.751871 1.00779C0.750502 0.831353 0.795838 0.657697 0.883279 0.504448C0.970719 0.351199 1.09715 0.223813 1.24974 0.135221C1.40232 0.0466299 1.57563 -1.23978e-05 1.75207 2.86102e-05L11.2521 2.86102e-05C11.4281 0.00086689 11.6008 0.0481491 11.7527 0.137098C11.9045 0.226048 12.0303 0.353512 12.1171 0.506612C12.204 0.659712 12.2489 0.833024 12.2473 1.00904C12.2457 1.18505 12.1977 1.35752 12.1081 1.50903L7.36107 9.54303Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconChevron',
  props: {
    width: {
      type: Number,
      default: 13,
    },
    height: {
      type: Number,
      default: 11,
    },
    color: String,
  },
}
</script>
