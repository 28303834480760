<template>
  <div class="Home"></div>
</template>

<script>
export default {
  name: 'Home',
}
</script>
<style lang="sass" scoped>
.Home
  height: 100vh
</style>
