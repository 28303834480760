<template>
  <div class="Welcome">
    <div class="Welcome--header">
      <img class="Welcome--header-logo" src="../assets/CupLogo.svg" alt="" />
      <div class="Welcome--header-title">Welcome!</div>
      <div class="Welcome--header-subtitle">Let's cup some coffee️</div>
    </div>
    <transition-group name="slide" class="Welcome--content" tag="div">
      <div v-if="state !== INITIAL" class="Welcome--inputs" key="inputs">
        <Input
          v-if="state === SIGNUP"
          prefix-icon="person"
          placeholder="Name"
        />
        <Input prefix-icon="email" placeholder="Email" />
        <Input prefix-icon="lock" type="password" placeholder="Password" />
      </div>
      <!-- TODO: Refactor this (add classes)-->
      <div key="top-buttons" style="margin-top: auto;">
        <Button
          class="Welcome--button"
          text="Log in"
          @click="state = LOGIN"
          :background-color="'#F4F4F4'"
          :text-color="'#453E44'"
        />
        <Button
          :text="this.state === INITIAL ? 'Sign up' : 'Back'"
          @click="state === INITIAL ? (state = SIGNUP) : (state = INITIAL)"
          key="top-buttons-1"
        />
      </div>
      <div v-if="state === INITIAL" key="bottom-buttons">
        <div class="Welcome--buttons-divider">
          <hr />
          <span class="Welcome--buttons-divider-text">or</span>
          <hr />
        </div>
        <Button
          text="Begin tasting"
          @click="modalVisible = true"
          background-color="#F4F4F4"
          text-color="#453E44"
        />
      </div>
    </transition-group>
    <div
      class="Welcome--buttons-bg"
      :style="{ height: state !== INITIAL ? '35%' : '50%' }"
    />
    <transition name="slide-up">
      <div v-if="modalVisible" class="Welcome--entry-modal">
        <div class="Welcome--entry-modal-close" @click="modalVisible = false">
          <Icon name="close" />
        </div>

        <div class="Welcome--entry-modal-text">
          Scan a QR code or add a coffee manually to get started!
        </div>
        <router-link class="Welcome--entry-modal-button" to="scan">
          <Button text="Scan QR code" background-color="#453E44" />
        </router-link>

        <Button
          class="Welcome--entry-modal-button"
          text="Enter Manually"
          text-color="#453E44"
          border-color="#453E44"
        />
      </div>
    </transition>
    <transition name="fade">
      <div v-if="modalVisible" class="Welcome--overlay" />
    </transition>
  </div>
</template>

<script>
import Button from '../components/Button'
import Input from '../components/Input'
import Icon from '../components/Icon'

const INITIAL = 0
const LOGIN = 1
const SIGNUP = 2

export default {
  name: 'Welcome',
  components: {
    Button,
    Input,
    Icon,
  },
  data() {
    return {
      state: INITIAL,
      INITIAL,
      LOGIN,
      SIGNUP,
      modalVisible: false,
    }
  },
  methods: {
    showModal() {},
  },
}
</script>

<style lang="sass" scoped>
@import "src/styles/variables"

.Welcome
  display: flex
  flex-flow: column
  justify-content: space-between
  height: 100%
  position: relative
  .Welcome--header
    text-align: left
    padding: 50px $content-padding-lr 0
    .Welcome--header-logo
      width: 125px
      margin-bottom: 40px
    .Welcome--header-title
      color: $text-color-blackish
      font-weight: 500
      font-size: 26px
      line-height: 36px
    .Welcome--header-subtitle
      color: $text-color-blackish
      font-size: 20px
      line-height: 27px
  .Welcome--content
    display: flex
    flex-flow: column
    height: 100%
    justify-content: space-between
    padding: 10% $content-padding-lr 40px $content-padding-lr
    position: relative
    > *
      transition: all 0.5s
    .Welcome--button:not(:last-child)
      margin-bottom: 12px
    .Welcome--buttons-divider
      color: $bg-color-primary
      display: flex
      margin: 24px 0
      .Welcome--buttons-divider-text
        width: 90px
      hr
        border-radius: 7px 7px 7px 7px
        border-style: solid
        width: 100%
  .Welcome--buttons-bg
    background-color: $fg-color-primary
    bottom: 0
    clip-path: polygon(0 25%, 100% 0, 100% 100%, 0% 100%)
    height: 35%
    left: 0
    position: absolute
    transition: height 400ms cubic-bezier(0.39, 0.58, 0.57, 1)
    width: 100vw
    z-index: -1
  .Welcome--entry-modal
    background-color: white
    border-radius: 30px 30px 0 0
    box-sizing: border-box
    bottom: 0
    display: flex
    flex-flow: column
    height: 40%
    justify-content: flex-end
    padding: 50px 30px
    position: absolute
    width: 100%
    z-index: 1
    .Welcome--entry-modal-close
      position: absolute
      top: 25px
      right: 25px
      height: 10px
      width: 10px
      padding: 5px
    .Welcome--entry-modal-text
      color: $fg-color-primary
      font-size: 18px
      margin: auto 10%
      text-align: center
    .Welcome--entry-modal-button:not(:last-child)
      margin-bottom: 12px
  .Welcome--overlay
    background: black
    opacity: .5
    position: absolute
    height: 100%
    width: 100%
.slide-enter,
.slide-leave-active
  transform: translateX(-1000px)
.slide-leave-active
  position: absolute

.slide-up-enter-active, .slide-up-leave-active
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1)
.slide-up-enter, .slide-up-leave-to
  transform: translateY(500px)

.fade-enter-active, .fade-leave-active
  transition: opacity 200ms
.fade-enter, .fade-leave-to
  opacity: 0
</style>
