<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.20594 4.42212H9.8984C10.6601 4.42212 11.2833 4.98462 11.2833 5.67212V11.9221C11.2833 12.6096 10.6601 13.1721 9.8984 13.1721H1.58888C0.827171 13.1721 0.203957 12.6096 0.203957 11.9221V5.67212C0.203957 4.98462 0.827171 4.42212 1.58888 4.42212H2.28134V3.17212C2.28134 1.44712 3.83245 0.0471191 5.74364 0.0471191C7.65483 0.0471191 9.20594 1.44712 9.20594 3.17212V4.42212ZM5.74364 1.29712C4.59415 1.29712 3.66626 2.13462 3.66626 3.17212V4.42212H7.82102V3.17212C7.82102 2.13462 6.89312 1.29712 5.74364 1.29712ZM1.58888 11.9221V5.67212H9.8984V11.9221H1.58888ZM7.12856 8.79712C7.12856 9.48462 6.50534 10.0471 5.74364 10.0471C4.98193 10.0471 4.35872 9.48462 4.35872 8.79712C4.35872 8.10962 4.98193 7.54712 5.74364 7.54712C6.50534 7.54712 7.12856 8.10962 7.12856 8.79712Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconLock',
  props: {
    width: {
      type: Number,
      default: 15,
    },
    height: {
      type: Number,
      default: 11,
    },
    color: String,
  },
}
</script>
