<template>
  <div class="Input" :style="`display: ${hidden ? 'none' : ''}`">
    <Icon
      class="Input--prefix-icon"
      v-if="prefixIcon"
      :name="prefixIcon"
      color="#272222"
    />
    <input class="Input--field" :placeholder="placeholder" :type="type" />
    <Icon class="Input--suffix-icon" v-if="suffixIcon" :name="suffixIcon" />
  </div>
</template>

<script>
import Icon from './Icon'
export default {
  name: 'Input',
  components: {
    Icon,
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: String,
    prefixIcon: String,
    suffixIcon: String,
    hidden: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="sass" scoped>
@import "src/styles/variables"
.Input
  align-items: center
  border-bottom: 1px solid $color-blackish
  display: flex
  font-size: 14px
  height: 45px
  opacity: .5
  text-align: left
  &:focus-within
    opacity: 1
  .Input--prefix-icon
    margin-right: 10px
  .Input--suffix-icon
    margin-left: auto
  .Input--field
    border: none
    outline: none
    width: 100%
</style>
