<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00073 10.447L4.0924 7.82202L3.12296 8.69702L7.00073 12.197L15.3103 4.69702L14.3408 3.82202L7.00073 10.447Z"
      fill="#272222"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCheck',
  props: {
    width: {
      type: Number,
      default: 18,
    },
    height: {
      type: Number,
      default: 16,
    },
    color: String,
  },
}
</script>
