<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 11 11"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.74362 0.867676C4.36237 0.867676 3.24362 1.98643 3.24362 3.36768C3.24362 4.74893 4.36237 5.86768 5.74362 5.86768C7.12487 5.86768 8.24362 4.74893 8.24362 3.36768C8.24362 1.98643 7.12487 0.867676 5.74362 0.867676ZM6.99361 3.36762C6.99361 2.68012 6.43111 2.11762 5.74361 2.11762C5.05611 2.11762 4.49361 2.68012 4.49361 3.36762C4.49361 4.05512 5.05611 4.61762 5.74361 4.61762C6.43111 4.61762 6.99361 4.05512 6.99361 3.36762ZM9.49361 8.99262C9.36861 8.54887 7.43111 7.74262 5.74361 7.74262C4.05611 7.74262 2.11861 8.54887 1.99361 8.99887V9.61762H9.49361V8.99262ZM0.743622 8.99267C0.743622 7.33018 4.07487 6.49268 5.74362 6.49268C7.41237 6.49268 10.7436 7.33018 10.7436 8.99267V10.8677H0.743622V8.99267Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconPerson',
  props: {
    width: {
      type: Number,
      default: 11,
    },
    height: {
      type: Number,
      default: 11,
    },
    color: String,
  },
}
</script>
