<template>
  <component
    :width="width"
    :height="height"
    :fill="color"
    :is="icon"
  ></component>
</template>

<script>
import Email from './icons/IconEmail'
import Lock from './icons/IconLock'
import Check from './icons/IconCheck'
import Chevron from './icons/IconChevron'
import Close from './icons/IconClose'
import Person from './icons/IconPerson'
import NavArrow from './icons/IconNavArrow'
import Scan from './icons/IconScan'
import Menu from './icons/IconMenu'
import Info from './icons/IconInfo'

export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    width: [Number, String],
    height: [Number, String],
    color: {
      type: String,
      default: '#272222',
    },
  },
  computed: {
    icon() {
      switch (this.name.toLowerCase().trim()) {
        case 'email':
          return Email
        case 'lock':
          return Lock
        case 'menu':
          return Menu
        case 'check':
          return Check
        case 'close':
          return Close
        case 'person':
          return Person
        case 'scan':
          return Scan
        case 'chevron':
          return Chevron
        case 'nav-arrow':
          return NavArrow
        case 'info':
          return Info
        default:
          return null
      }
    },
  },
}
</script>

<style lang="sass" scoped>
svg
  display: inline-block
  vertical-align: baseline
</style>
