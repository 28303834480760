<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 50 50"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="25" cy="25" r="25" />
  </svg>
</template>

<script>
export default {
  name: 'Circle',
  props: {
    color: {
      type: String,
      default: '#272222',
    },
    width: [Number, String],
    height: [Number, String],
  },
}
</script>

<style scoped></style>
