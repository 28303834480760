<template>
  <Card class="RoastCard" title="Roast">
    <template v-slot:indicator>
      <Circle style="transition: fill .3s;" :color="roast.toColor()" width="40px" height="40px" />
    </template>
    <template v-slot:details>
      <div class="RoastCard-roast">
        {{ roast.toLabel() }}
      </div>
    </template>
    <template v-slot:content>
      <div class="RoastCard--content">
        <Icon
          class="RoastCard--roast-selector"
          :style="{ transform: `translateX(${roast.score}00%)` }"
          name="chevron"
        />
        <Circle
          class="RoastCard--roast-select"
          v-for="(color, index) in Object.values(roastColor)"
          :key="index"
          :color="color"
          :width="30"
          @click="updateRoast(index)"
        />
      </div>
    </template>
  </Card>
</template>

<script>
import Card from './Card'
import Circle from './Circle'
import Icon from './Icon'
import Roast from '../models/Roast'
import { mapActions } from 'vuex'

export default {
  name: 'RoastCard',
  props: {
    sample: Object,
  },
  components: {
    Card,
    Circle,
    Icon,
  },
  computed: {
    circleColor(roastScore = this.roast.score) {
      switch (roastScore) {
        case Roast.LIGHT:
          return '#C6955B'
        case Roast.CINNAMON:
          return '#93642C'
        case Roast.STANDARD:
          return '#7C480A'
        case Roast.MEDIUM:
          return '#58350C'
        case Roast.DARK:
          return '#3B2000'
        default:
          return ''
      }
    },
  },
  data() {
    return {
      roast: new Roast(this.sample.roast),
      roastColor: {
        light: Roast.toColor(Roast.LIGHT),
        cinnamon: Roast.toColor(Roast.CINNAMON),
        standard: Roast.toColor(Roast.STANDARD),
        medium: Roast.toColor(Roast.MEDIUM),
        dark: Roast.toColor(Roast.DARK),
      },
    }
  },
  methods: {
    ...mapActions(['updateSample']),
    updateRoast(score) {
      this.roast.score = score
      const updatedSample = { ...this.sample, roast: this.roast }
      this.updateSample(updatedSample)
    },
  },
}
</script>

<style lang="sass" scoped>
.RoastCard
  .RoastCard--roast-indicator
    background-color: #58350C
    border-radius: 50%
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161)
    padding-bottom: 75%
    width: 75%
  .RoastCard--content
    display: grid
    grid-template-columns: repeat(5, 1fr)
    .RoastCard--roast-selector
      grid-column-end: 6
      grid-column-start: 1
      width: 20%
      transition: transform .3s
    .RoastCard--roast-select
      box-sizing: border-box
      padding: 10px
      width: 100%
    .RoastCard--roast-selections
      align-items: center
      display: flex
      justify-content: center
      margin-top: 10px
      .RoastCard--roast-select:not(:last-child)
        padding: 10px
</style>
