<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 80 74"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.917 69.375H17C13.691 69.375 11 66.684 11 63.375V51.834C11 50.73 10.104 49.834 9 49.834C7.896 49.834 7 50.73 7 51.834V63.375C7 68.889 11.486 73.375 17 73.375H34.917C36.021 73.375 36.917 72.479 36.917 71.375C36.917 70.271 36.021 69.375 34.917 69.375ZM45.083 4.625H63C66.309 4.625 69 7.316 69 10.625V38.334C69 39.438 69.896 40.334 71 40.334C72.104 40.334 73 39.438 73 38.334V10.625C73 5.111 68.514 0.625 63 0.625H45.083C43.979 0.625 43.083 1.521 43.083 2.625C43.083 3.729 43.979 4.625 45.083 4.625ZM9 40.334C10.104 40.334 11 39.438 11 38.334V10.625C11 7.316 13.691 4.625 17 4.625H34.917C36.021 4.625 36.917 3.729 36.917 2.625C36.917 1.521 36.021 0.625 34.917 0.625H17C11.486 0.625 7 5.111 7 10.625V38.334C7 39.438 7.896 40.334 9 40.334ZM71 49.834C69.896 49.834 69 50.73 69 51.834V63.375C69 66.684 66.309 69.375 63 69.375H45.083C43.979 69.375 43.083 70.271 43.083 71.375C43.083 72.479 43.979 73.375 45.083 73.375H63C68.514 73.375 73 68.889 73 63.375V51.834C73 50.729 72.104 49.834 71 49.834ZM77.75 43H2.25C1.146 43 0.25 43.896 0.25 45C0.25 46.104 1.146 47 2.25 47H77.75C78.854 47 79.75 46.104 79.75 45C79.75 43.896 78.854 43 77.75 43ZM39 11.001H17V33.001H39V11.001ZM35 29.001H21V15.001H35V29.001ZM46.333 11.001H42.333V21.001H46.333V11.001ZM58.333 24.625H42.333V40.625H58.333V24.625ZM54.333 36.625H46.333V28.625H54.333V36.625ZM38.625 40.834V36.834H24.833V40.834H38.625ZM21 51.001H17V62H40V58H21V51.001ZM64.875 11.001H49.625V15.001H60.875V26H64.875V11.001ZM49.625 22H55.333V18H49.625V22ZM60.875 38.834H64.875V32.834H60.875V38.834ZM25.625 51.375V55.375H36V51.375H25.625ZM42.083 50.188V54.188H52.083V58H49.333V62H64.875V50H60.875V58H56.083V50.187H42.083V50.188ZM30.625 19.75H24.625V25.75H30.625V19.75ZM53.083 29.875H47.583V35.375H53.083V29.875ZM17 40.667H21V35.626H17V40.667Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconLock',
  props: {
    width: {
      type: String,
      default: '80px',
    },
    height: {
      type: String,
      default: '74px',
    },
    color: {
      type: String,
      default: '#272222',
    },
  },
}
</script>
