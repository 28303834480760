<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 27 10"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.5 0H1.5C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3H25.5C26.3284 3 27 2.32843 27 1.5C27 0.671573 26.3284 0 25.5 0Z"
    />
    <path
      d="M16.5 7H1.5C0.671573 7 0 7.67157 0 8.5C0 9.32843 0.671573 10 1.5 10H16.5C17.3284 10 18 9.32843 18 8.5C18 7.67157 17.3284 7 16.5 7Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMenu',
  props: {
    width: {
      type: Number,
      default: 27,
    },
    height: {
      type: Number,
      default: 10,
    },
    color: String,
  },
}
</script>
