export default {
  UPDATE(state, { key, value }) {
    state[key] = value
  },
  PUSH(state, { key, value }) {
    if (!state[key] || !Array.isArray(state[key])) return
    state[key].push(value)
  },
  DELETE(state, { key, idx }) {
    if (!state[key] || !Array.isArray(state[key])) return
    state[key].splice(idx, 1)
  },
  SET_SYNC(state, sync) {
    state.sync = sync
  },
}
