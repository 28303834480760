<template>
  <div :class="['Card', { 'Card--open': isOpen }]">
    <div class="Card--header" @click="collapse">
      <div class="Card--indicator">
        <slot name="indicator"></slot>
      </div>
      <div class="Card--header-content">
        <div class="Card--header-title">{{ title }}</div>
        <div class="Card--header-details">
          <slot name="details"></slot>
        </div>
      </div>
      <div class="Card--header-actions">
        <Icon style="margin-right: 24px" name="info" :width="14" />
        <Icon
          class="Card--header-actions-open"
          :open="isOpen"
          name="nav-arrow"
          :width="8"
        />
      </div>
    </div>
    <div class="Card--content-container" ref="collapseContent">
      <div class="Card--content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../components/Icon'

export default {
  name: 'Card',
  components: {
    Icon,
  },
  props: {
    title: String,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    collapse() {
      let el = this.$refs.collapseContent
      if (!this.isOpen) {
        this.isOpen = true
        let maxHeight = el.firstElementChild.offsetHeight
        el.setAttribute('style', `max-height: ${maxHeight + 1}px`)
      } else {
        this.isOpen = false
        el.removeAttribute('style')
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.Card
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161)
  border-radius: 8px
  padding: 18px
  .Card--header
    align-items: center
    display: flex
    flex-flow: row
    text-align: left
    text-transform: capitalize
    .Card--indicator
      display: flex
      width: 20%
    .Card--header-title
      color: #140906
      font-weight: 500
      font-size: 18px
      line-height: 25px
    .Card--header-details
      color: #888888
      font-size: 13px
      line-height: 18px
    .Card--header-actions
      margin-left: auto
      .Card--header-actions-open
        transform: rotate(-90deg)
        transition: transform 200ms
        &[open="true"]
          transform: rotate(90deg)
  .Card--content-container
    max-height: 0
    overflow: hidden
    transition: max-height 320ms ease
    .Card--content
      padding: 15px 0
</style>
